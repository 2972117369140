import m from 'mithril'
import * as Page from 'front/pages'

const root = document.body

const managerRoutes = {
    '/': Page.DashboardPage,
}

export function render() {
    m.route(root, '/', managerRoutes)
}
