import m from 'mithril'
import classNames from 'classnames'
import {
    Dialog
} from 'components'
import QuestionDialog from './dialogs/question'

class DashboardPage {
    constructor() {
        this.questions = [
            {
                order: "01", color: '#9ecce8', answer: 1, isFinish: false, question: "以下的敘述何者正確?",
                items: ["乾癬只需要持續配合治療，就可以根治", "乾癬是一種慢性皮膚發炎病變",
                    "乾癬是具傳染性的皮膚疾病", "乾癬只要病灶消失就可以停藥"]
            },
            {
                order: "02", color: '#79c2f2', answer: 3, isFinish: false,
                question: "下列何者是乾癬常見的症狀?",
                items: ["皮膚增厚", "病灶處脫屑", "皮膚出現紅斑", "以上皆是"]
            },
            {
                order: "03", color: '#6dadc6', answer: 2, isFinish: false,
                question: "乾癬目前在台灣的盛行率為?",
                items: ["男性罹病的比率跟女性罹病的相當", "男性罹病比率較女性罹病比率低", "男性罹病比率較女性罹病比率高"]
            },
            {
                order: "04", color: '#79c2f2', answer: 0, isFinish: false,
                question: "常見的乾癬類型是哪種?",
                items: ["斑塊性乾癬", "滴狀乾癬", "紅皮型乾癬(乾癬性紅皮症)", "膿疱性乾癬"]
            },
            {
                order: "05", color: '#79c2f2', answer: 2, isFinish: false,
                question: "下列何者不是乾癬發作的危險因子?",
                items: ["基因遺傳", "環境因子(外傷、感染、壓力)", "營養不良", "行為因素(抽菸、藥物等)",]
            },
            {
                order: "06", color: '#2ca6e0', answer: 3, isFinish: false,
                question: "下列何者不是乾癬的共病症?",
                items: ["心理疾病", "乾癬性關節炎", "心血管疾病", "腎臟疾病"]
            },
            {
                order: "07", color: '#79c2f2', answer: 2, isFinish: false,
                question: "下列何者為評估乾癬嚴重度的方法?",
                items: ["體表面積（Body surface area, BSA）評估法", "乾癬面積暨嚴重度指數（Psoriasis Area and Severity Index, PASI）", "以上皆是"]
            },
            {
                order: "08", color: '#2ca6e0', answer: 0, isFinish: false,
                question: "體表面積（Body surface area, BSA）評估法，是評估乾癬病灶面積佔全身面積的比例，估算方法為?",
                items: ["一個手掌面積約為全身面積的1%", "一個手掌面積約為全身面積的1.5%", "一個手掌面積約為全身面積的3%", "一個手掌面積約為全身面積的2%"]
            },
            {
                order: "09", color: '#6dadc6', answer: 1, isFinish: false,
                question: "評估患者為中度-重度乾癬標準為?",
                items: ["體表面積(BSA)≧ 11或乾癬面積暨嚴重度指數(PASI) ≧ 10", "體表面積(BSA)≧ 10或乾癬面積暨嚴重度指數(PASI) ≧ 10", "體表面積(BSA)≦10或乾癬面積暨嚴重度指數(PASI) ≦ 10", "以上皆非"]
            },
            {
                order: "10", color: '#9ecce8', answer: 3, isFinish: false,
                question: "下列何者不是中度至重度乾癬病人的治療方法?",
                items: ["生物製劑", "口服藥物", "全身性照光治療", "點滴注射"]
            },
            {
                order: "11", color: '#79c2f2', answer: 0, isFinish: false,
                question: "若患者合併有乾癬性關節炎，須採用何種治療?",
                items: ["全身性治療(生物製劑, 口服藥物) + 輔助治療(局部治療, 照光治療)", "使用生物製劑治療即可", "使用口服藥物治療即可", "以上皆是"]
            },
            {
                order: "12", color: '#79c2f2', answer: 2, isFinish: false,
                question: "下列何者不是乾癬的局部治療項目?",
                items: ["外用類固醇", "維生素D類似藥物", "口服類固醇", "外用A酸(Tazarotene)"]
            },
            {
                order: "13", color: '#1884c6', answer: 1, isFinish: false,
                question: "醫病共享決策的英文縮寫為何?", items: ["DDM", "SDM", "DSM", "SNQ"]
            },
            {
                order: "14", color: '#79c2f2', answer: 2, isFinish: false,
                question: "下列何者不是推廣醫病共享決策對病人的好處?",
                items: ["改善衛教品質", "減低決策衝突", "增加病人選擇困難", "提升病人接受度"]
            },
            {
                order: "15", color: '#1884c6', answer: 1, isFinish: false,
                question: "下列何者不是推廣醫病共享決策對醫護人員的好處?",
                items: ["提高病人滿意度", "增加醫病衝突", "改善病人癒後", "幫助設定病人合理的期待"]
            },
            {
                order: "16", color: '#79c2f2', answer: 1, isFinish: false,
                question: "乾癬生物製劑藥物-介白素-23拮抗劑 ”喜開悅”在施打誘導劑量後的治療頻率為何?",
                items: ["每8週一次", "每12週一次", "每4週一次", "每2週一次"]
            },
            {
                order: "17", color: '#1884c6', answer: 3, isFinish: false,
                question: "下列關於醫病共享決策的敘述何者正確?",
                items: ["醫師和病人共同討論及參與健康決策的過程。", "1982年美國以病人為中心照護的共同福祉計畫上，為促進醫病相互尊重與溝通而提出。", "交集了「以病人為中心的溝通技巧」和「實證醫學」，達成最佳病人照護模式的終極目標。", "以上皆是"]
            },
            {
                order: "18", color: '#79c2f2', answer: 2, isFinish: false,
                question: "乾癬生物製劑藥物-介白素-23拮抗劑 ”喜開悅”，英文學名為何?",
                items: ["Risaizumab", "Risankiz", "Risankizumab"]
            },
            {
                order: "19", color: '#2ca6e0', answer: 0, isFinish: false,
                question: "下列關於乾癬生物製劑的描述，是否正確?",
                description: "是一種注射性的蛋白質，可阻斷特定發炎因子，進而有效所小病灶、控制病情、延緩病情。",
                items: ["是", "否"]
            },
            {
                order: "20", color: '#2ca6e0', answer: 2, isFinish: false,
                question: "目前台灣核准的生物製劑類別有?",
                items: ["5大類10種。", "4大類8種", "4大類9種", "3大類9種"]
            },
            {
                order: "21", color: '#9ecce8', answer: 3, isFinish: false,
                question: "乾癬生物製劑藥物-介白素-23拮抗劑 ”喜開悅”，建議施打劑量為?",
                items: ["200mg", "150mg", "100mg", "250mg"]
            },
            {
                order: "22", color: '#6dadc6', answer: 1, isFinish: false,
                question: "下列何者不是乾癬生物製劑的種類?",
                items: ["腫瘤壞死因子抑制劑", "介白素-13拮抗劑", "介白素-17拮抗劑/抗體", "介白素-12/23拮抗劑"]
            },
            {
                order: "23", color: '#79c2f2', answer: 1, isFinish: false,
                question: "乾癬生物製劑藥物-介白素-23拮抗劑 ”喜開悅”，施打方式為何?",
                items: ["肌肉注射", "皮下注射", "靜脈注射"]
            },
            {
                order: "24", color: '#6dadc6', answer: 0, isFinish: false,
                question: "乾癬生物製劑藥物-介白素-23拮抗劑 ”喜開悅”，其作用機轉為何?",
                items: ["阻斷IL-23和受體結合，抑制IL-23相關細胞訊息傳遞和促進發炎細胞激素的釋放", "阻斷IR-23和受體結合，抑制IR-23相關細胞訊息傳遞和促進發炎細胞激素的釋放", "阻斷IL-13和受體結合，抑制IL-14相關細胞訊息傳遞和促進發炎細胞激素的釋放"]
            },
            {
                order: "25", color: '#2ca6e0', answer: 0, isFinish: false,
                question: "乾癬生物製劑藥物-介白素-23拮抗劑 ”喜開悅”的適應症為何?",
                items: ["接受全身性治療的中度至重度斑塊性乾癬成人病人","接受全身性治療的輕度至中度斑塊性乾癬成人病人","接受全身性治療的重度斑塊性乾癬成人病人"]
            },
        ]
    }
    oncreate(vnode) {

    }

    view() {
        return [
            m(".container", [
                m(".row.no-gutters.w-100.px-5", [
                    m(".col-md-12.py-3", [
                        m(".d-flex.justify-content-between", [
                            m("img.img-fluid", {
                                src: `./img/logo1.png`,
                                maxWidth: "250px",
                                maxHeight: "80px"
                            }),
                            m(".d-flex.align-items-center", [
                                m(".btn.btn-outline-dark", {
                                    style: {
                                        color: "#1884c6",
                                        fontSize: "1.5rem",
                                        textShadow: "1px 0 #1884c6",
                                        borderRadius: "15px"
                                    },
                                    onclick: (e) => {
                                        e.preventDefault()
                                        this.questions.map((_question) => {
                                            _question.isFinish = false
                                        })
                                    }
                                }, "RESTART")
                            ])
                        ])
                    ]),
                ]),
                m(".row.no-gutters.w-100.row-cols-5.px-5", [
                    this.questions.map((_question, index) => {
                        return [
                            m(".col.pt-2.pb-2", {
                                style: {
                                    cursor: "pointer"
                                },
                                class: classNames({
                                    'pr-2': index % 5 == 0,
                                    'px-1': index % 5 == 1 || index % 5 == 2 || index % 5 == 3,
                                    'pl-2': index % 5 == 4,
                                }),
                                onclick: (e) => {
                                    e.preventDefault()
                                    if (!_question.isFinish) {
                                        Dialog.show(QuestionDialog, {
                                            attrs: {
                                                model: _question
                                            },
                                        })
                                    }
                                }
                            }, [
                                m(".p-3.d-flex.justify-content-center.align-items-center.flex-column", {
                                    style: {
                                        background: (_question.isFinish) ? "#eeeeef" : _question.color,
                                        borderRadius: "30px",
                                        minHeight: "14vh"
                                    }
                                }, [
                                    m("span.font-weight-bold", {
                                        style: {
                                            color: (_question.isFinish) ? "#2ca6e0" : "#FFFFFF",
                                            fontSize: "2rem"
                                        }
                                    }, "BINGO"),
                                    m("span.font-weight-bold", {
                                        style: {
                                            textShadow: (_question.isFinish) ? "1px 0 #2ca6e0" : "1px 0 #FFFFFF",
                                            color: (_question.isFinish) ? "#2ca6e0" : "#FFFFFF",
                                            fontSize: "5rem",
                                            lineHeight: "4.25rem"
                                        }
                                    }, _question.order),

                                ])
                            ])
                        ]
                    })

                ])

            ]),
            m(Dialog),
        ]
    }
}

export default DashboardPage